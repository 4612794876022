"use client";

import Image from "next/image";
import { ArrowDown } from "react-feather";

export const Scroll = () => {
	const onScroll = () => {
		// scroll 100vh down
		window.scrollTo({
			top: window.innerHeight - 128,
			behavior: "smooth",
		});
	};

	return (
		<div className="grabber__scroll" onClick={onScroll}>
			<Image
				unoptimized
				src="/images/svg/blob.svg"
				alt="scroll"
				width={96}
				height={96}
				className="grabber__scroll__image"
				style={{
					position: "absolute",
					transform: "rotate(120deg)",
				}}
			/>
			<div className="grabber__scroll__icon">
				<ArrowDown size={36} />
			</div>
		</div>
	);
};
